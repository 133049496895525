import Category from "./category";
import Home from "./home";
import NotFound from "./notFound";
import Post from "./post";
import Privacy from "./privacy";
import Terms from "./terms";

const routes = [
  { name: "Home", slug: "/", element: <Home /> },
  { name: "404 Error", slug: "*", element: <NotFound /> },
  { name: "Post Details", slug: "/post/:slug", element: <Post /> },
  { name: "Categories", slug: "/category/:slug", element: <Category /> },
  { name: "Privacy Policy", slug: "/privacy", element: <Privacy /> },
  { name: "Terms of use", slug: "/terms", element: <Terms /> },

];

export default routes;
