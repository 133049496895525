import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Container, Wrapper, Header, Title, Image, Timestamp, Excerpt } from "./styled";
export default function PostWidget({ post }) {
  console.log(post);

  return (
    <Container initial={{ opacity: 0 }} whileInView={{ opacity: [0, 0, 1] }} transition={{ duration: 0.5 }}>
      <Wrapper dir={post.arabic && "rlt"}>
        <Header>
          <img src={post.author.photo.url} alt={post.author.name} />
          <span>{post.author.name} .</span>
          <Timestamp>{moment(post.createdAt).format("MMM DD, YYYY")}</Timestamp>
        </Header>
        <Image>
          <img src={post.image.url} alt={post.title} />
        </Image>
        <Link to={`/post/${post.slug}`}>
          <Title style={{ textAlign: post.arabic ? "end" : "start" }}>{post.title}</Title>
        </Link>
        <Excerpt style={{ textAlign: post.arabic ? "end" : "start" }}>{`${post.excerpt.substring(0, 262)}...`}</Excerpt>
      </Wrapper>
    </Container>
  );
}
