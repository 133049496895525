import React from "react";
import { usePosts, RouteContainer, PostWidget, MetaTag } from "../../components";
import { Article, Container, FeaturePost, Wrapper } from "./styled";

export default function Home() {
  const { posts } = usePosts();

  return (
    <RouteContainer>
      <MetaTag description="osmx blog is a one-stop destination for programming, tutorials, tech, news, code snippets articles" />
      <Container>
        {posts.length && (
          <Wrapper>
            <FeaturePost>
              <PostWidget post={posts[0].node} />
            </FeaturePost>
            <Article>
              {posts.slice(1).map((prop) => (
                <PostWidget post={prop.node} key={prop.node.slug} />
              ))}
            </Article>
          </Wrapper>
        )}
      </Container>
    </RouteContainer>
  );
}
