import { useContext } from "react";
import { PostsState } from "../context/PostsProvider";

export default function usePosts() {
  const context = useContext(PostsState);
  if (context === undefined) {
    throw new Error("usePost must be used within a PostProvider");
  }

  const latestPosts = context.posts.slice(0, 3);

  return { ...context, latestPosts };
}
