import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { Container, Logo, Navigation, Sticky, Wrapper } from "./styled";
import { getCategories } from "../../services";

export default function Header() {
  const ref = useRef();
  const [sticky, setSticky] = useState(false);
  const [categories, setCategories] = useState([]);

  document.addEventListener("scroll", () => {
    if (window.scrollY > 200) setSticky(true);
    else setSticky(false);
  });

  useEffect(() => {
    (async () => {
      const response = await getCategories();
      setCategories(response.sort((a, b) => b.posts.length - a.posts.length).slice(0, 6));
    })();
  }, []);

  return (
    <Container ref={ref} id="header">
      <Sticky className={sticky && "sticky"}>
        <Wrapper>
          <Logo>
            <NavLink to="/">
              <span>OSMX</span> BLOG
            </NavLink>
          </Logo>
          <Navigation>
            {categories.map((prop) => (
              <Button key={prop.slug} color="secondary" variant="text">
                <NavLink to={`/category/${prop.slug}`}>{prop.name}</NavLink>
              </Button>
            ))}
          </Navigation>
        </Wrapper>
      </Sticky>
    </Container>
  );
}
