import { queries } from "../../config";
import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--secondary);
  color: var(--white);
  box-shadow: 0 0 15px var(--rgba-5);
  display: flex;
  justify-content: center;
  /* background-image: var(--background); */
`
export const Wrapper = styled.div`
  padding: 1rem;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 0;

  @media ${queries.mobile} {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    gap: 2rem;
  }
`
export const Author = styled.div`
  font-size: .8rem;
  color: rgba(255, 255, 255, 0.4);

  a {
    color: var(--green);
    font-weight: bold;
  }
`

export const MainSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
  width: 20%;
  p {
    font-size: .8rem;
  }

  @media ${queries.mobile} {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`
export const Logo = styled.div``
export const RoutesWrapper = styled.div`
  display: flex;
  gap: 5rem;
  @media ${queries.mobile} {
    flex-direction: column;
  }
`
export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`
export const Header = styled.div`
  font-size: 1rem;
`
export const Navigators = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: .9rem;
  a {
    color: rgb(155, 157, 159);
    :hover {
      color: var(--green);
    }
  }
`