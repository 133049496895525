import React, { createContext, useState, useEffect } from "react";
import Loader from "../loader";
import { getPosts } from "../../services";

const PostsState = createContext();

export default function PostsProvider({ children }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = (await getPosts()) || [];
      const sorted = response.sort((a, b) => new Date(b.node.createdAt).getTime() - new Date(a.node.createdAt).getTime());
      setPosts(sorted);
      setLoading(false);
    })();
  }, []);

  return <PostsState.Provider value={{ posts, setPosts }}>{loading ? <Loader fullScreen /> : children}</PostsState.Provider>;
}

export { PostsState };
