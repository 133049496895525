import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    primary: {
      main: "#e33e5a"
    },
    secondary: {
      main: "#1a1b2f"
    }
  }
});