import styled, { keyframes } from "styled-components";

const appear = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`

export const Container = styled.div`
  height: 80px;
  width: 100%;
  display: grid;
  place-items: center;
`
export const Sticky = styled.div`
  height: 80px;
  background-color: var(--primary);
  width: 100%;
  display: grid;
  place-items: center;
  animation-fill-mode: forwards;
  animation-duration: 300ms;
  box-shadow: 0 0 20px var(--rgba-4);

  &.sticky {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 888;
    animation-name: ${appear};
    .motion-container {
      margin-top: 80px;
    }
  }
`
export const Wrapper = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
`
export const Logo = styled.h1`
  font-size: 2em;
  font-weight: 800;
  span {
    font-weight: 500;
  }
`
export const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  a {
    font-weight: bold;
    @media (max-width: 600px) {
      font-size: .7rem;
    }
  }
`