import styled from "styled-components";
import { queries } from "../../config";

export const Container = styled.div`
  display: grid;
  place-items: center;
  margin: 1rem 0 3rem 0;
`
export const Wrapper = styled.div`
  width: 85%;
  @media ${queries.mobile} {
    width: 90%;
  }
`

export const Article = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  @media ${queries.mobile} {
    grid-template-columns: 1fr;
  }
`
export const FeaturePost = styled.div`
  margin-bottom: 2rem;
`
export const Sidebar = styled.div`
  background-color: salmon;
`