import { ThemeProvider } from "@mui/material/styles";
import { Footer, Header, PostsProvider } from "./components";
import theme from "./utils/theme";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import routes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Header />
        <PostsProvider>
          <Routes>
            {routes.map((prop, index) => (
              <Route path={prop.slug} key={index} element={prop.element} />
            ))}
          </Routes>
        </PostsProvider>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}
