import React, { useEffect, useRef, useState } from "react";
import { Loader, MetaTag, RouteContainer, ShareButtons, usePosts } from "../../components";
import { getPostDetails, getSimilarPosts } from "../../services";
import {
  Container,
  Wrapper,
  Content,
  Header,
  Feature,
  Article,
  Sidebar,
  Controller,
  Chips,
  Share,
  SidebarGroup,
  SidebarGroupIcon,
  SidebarGroupHeader,
  SidebarGroupWrapper,
} from "./styled";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Speech from "speak-tts";
import { IconButton } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";

export default function Post() {
  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(false);
  const [boundary, setBoundary] = useState(0);
  const [similarPosts, setSimilarPosts] = useState([]);
  const refContent = useRef("");
  const Location = useLocation();
  const speech = new Speech(); // will throw an exception if not browser supported
  const { latestPosts } = usePosts();
  
  speech.init({
    volume: 1,
    lang: "en-US",
    rate: 1,
    pitch: 1,
    voice: "Microsoft David - English (United States)",
    splitSentences: true,
  });

  useEffect(() => {
    speech.cancel();
    (async () => {
      try {
        setLoading(true);
        const slug = Location.pathname.split("/").pop();
        const response = await getPostDetails(slug);
        const [similarPosts] = await Promise.all([
          getSimilarPosts(
            slug,
            response.categories.map((x) => x.slug)
          ),
        ]);

        setPost(response);
        refContent.current = response.content.html;
        if (similarPosts.length) setSimilarPosts(similarPosts);
        setLoading(false);
      } catch (error) {
        toast.error("something went wrong. please try again later.");
      }
    })();
    // return unsubscribe;
  }, [Location.pathname]);

  const onListen = () => {
    try {
      if (!Object.keys(post).length) return;
      if (boundary) speech.resume();
      else
        speech
          .speak({
            text: post.content.text.replace(/\\n/g, "").replace(/<[\s\S]*?\/>/g, ""),
            queue: false,
            listeners: {
              onboundary: (event) => {
                setBoundary(event.elapsedTime);
                const target = event.currentTarget.text;
                setPost((state) => ({
                  ...state,
                  content: {
                    ...state.content,
                    html: refContent.current.replace(target, `<mark>${target}</mark>`),
                  },
                }));
              },
            },
          })
          .then(() => {
            console.log("Success !");
          })
          .catch((e) => {
            console.error("An error occurred :", e);
          });
    } catch (error) {
      toast.error("Something went wrong.");
    }
  };

  if (loading || !Object.keys(post).length) return <Loader fullScreen />;
  else
    return (
      <RouteContainer>
        <Container dir={post.arabic && "rtl"}>
          <Wrapper>
            <MetaTag pageTitle={post.title} title={post.title} image={post.image.url} description={post.excerpt} />
            <Content>
              <Chips>
                {post.categories.map((prop) => (
                  <Link key={prop.slug} to={`/category/${prop.slug}`}>
                    {prop.name}
                  </Link>
                ))}
              </Chips>
              <Header>
                <h1>
                  {post.title}
                  <span>{moment(post.createdAt).format("MMM DD, YYYY")}</span>
                </h1>
                <Feature>
                  <img src={post.image.url} alt="" />
                </Feature>
              </Header>
              <Share>
                <span>
                  <i className="fa-solid fa-share-nodes"></i>Share
                </span>
                <ShareButtons />
              </Share>
              <Controller className={post.arabic ? "disabled" : ""}>
                <p>Listen to the article</p>
                <i>Beta</i>
                <IconButton onClick={onListen} color="inherit" title="Play/Resume">
                  <PlayCircleIcon />
                </IconButton>
                <IconButton onClick={() => speech.pause()} color="inherit" title="Pause">
                  <StopCircleIcon />
                </IconButton>
              </Controller>
              <Article dangerouslySetInnerHTML={{ __html: post.content.html }}></Article>
            </Content>
            <Sidebar className={post.arabic ? "arabic" : ""}>
              {similarPosts.length > 0 && (
                <SidebarGroup>
                  <h3>Related Posts</h3>
                  <SidebarGroupWrapper>
                    {similarPosts.map((prop) => (
                      <Link key={prop.slug} to={`/post/${prop.slug}`}>
                        <SidebarGroupHeader>
                          <h4>{prop.title}</h4>
                          <span>{moment(prop.createdAt).format("MMM DD, YYYY")}</span>
                        </SidebarGroupHeader>
                        <SidebarGroupIcon>
                          <img src={prop.image.url} alt="" />
                        </SidebarGroupIcon>
                      </Link>
                    ))}
                  </SidebarGroupWrapper>
                </SidebarGroup>
              )}
              {latestPosts.length > 0 && (
                <SidebarGroup>
                  <h3>Latest Posts</h3>
                  <SidebarGroupWrapper>
                    {latestPosts.map((prop) => (
                      <Link key={prop.node.slug} to={`/post/${prop.node.slug}`}>
                        <SidebarGroupHeader>
                          <h4>{prop.node.title}</h4>
                          <span>{moment(prop.node.createdAt).format("MMM DD, YYYY")}</span>
                        </SidebarGroupHeader>
                        <SidebarGroupIcon>
                          <img src={prop.node.image.url} alt="" />
                        </SidebarGroupIcon>
                      </Link>
                    ))}
                  </SidebarGroupWrapper>
                </SidebarGroup>
              )}
            </Sidebar>
          </Wrapper>
        </Container>
      </RouteContainer>
    );
}
