import styled from "styled-components";
import { queries } from "../../config";

export const Container = styled.div`
  min-height: calc(100vh - 80px);
  display: grid;
  place-items: center;
  margin-bottom: 3rem;
`
export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  width: 90%;
  position: relative;
  margin-top: 3rem;
  grid-gap: 1rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 90%;
  }
`
export const Content = styled.div`
  position: relative;
  width: 100%;
  
  @media (max-width: 600px) {
    width: 80%;
  }
`
export const Chips = styled.div`
  a {
    padding: 0.2rem 1rem;
    background-color: var(--secondary);
    color: var(--white);
    border-radius: .4rem;
    font-size: .7rem;
  }
  margin-bottom: .2rem;
  display: flex;
  gap: .5rem;

`
export const Share = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  gap: 1rem;
  span {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  @media ${queries.mobile} {
    flex-direction: column;
  }
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h1 {
    margin-bottom: 1rem;
    span {
      font-size: 1rem;
      background-color: var(--primary);
      padding: 0 .5rem;
      border-radius: 1rem;
      color: var(--white);
      display: table;
      @media ${queries.mobile} {
        font-size: .7rem;
      }
    }
  }

  img {
    object-fit: cover;
    width: 100%;
  } 
`
export const Feature = styled.div``
export const Controller = styled.div`

  padding: .2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  background-color: var(--secondary);
  border-radius: 2rem;
  position: relative;
  overflow: hidden;
  text-transform: capitalize;
  i {
    background-color: var(--primary);
    color: var(--secondary);
    padding: 1rem;
    position: absolute;
    font-weight: bold;
    left: 0;
  }
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;

    ::after {
      content: "Not available";
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.7);
      font-weight: bold;
    }
  }
`
export const Article = styled.article`
  margin-top: 1rem;
  p {
    line-height: 32px;
    /* margin-bottom: -0.46em; */
    margin-top: .4em;
    font-size: 20px;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 28px;
    margin-top: 3.14em;
    font-size: 22px;
    /* margin-bottom: -0.37em; */
    font-weight: 600;
  }
  h1, h2 {
    font-size: 28px;
    font-weight: bold;
  }
  mark {
    background-color: var(--primary-5);
    transition: 200ms;
  }
  pre {
    max-width: 100%;
    overflow-x: auto;
    margin:1em 0;
    padding: 20px;
    border: 1px solid #000;
    color: var(--white);
    line-height: 1.5em;
    background-color: var(--secondary);
    border-radius: .5rem;

    code {
      white-space: pre;
      ::selection {
        background-color: var(--primary);
      }
    }
  }
`
export const Sidebar = styled.div`
  border-left: 2px solid var(--rgba-1);
  padding: 1rem;
  &.arabic {
    border-left: none;
    border-right: 2px solid var(--rgba-1);
  }
  @media ${queries.mobile} {
    border-left: none;
    border-top: 2px solid var(--rgba-1);
  }
`

export const SidebarGroup = styled.div``
export const SidebarGroupWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  a {
    transition: 300ms;
    padding: .5rem;
    display: flex;
    gap: 1rem;
    border-radius: .5rem;
    :hover {
      background-color: var(--rgba-1);
    }
  }
`
export const SidebarGroupHeader = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  font-size: .8rem;
`
export const SidebarGroupIcon = styled.div`
  overflow: hidden;
  position: relative;
  width: 30%;
  img {
    object-fit: cover;
    width: 100%;
    height: 70%;
  }
`