import React from "react";

export default function MetaTag({ title, description, image }) {
  if (!image) image = "/OSMX_TECH_2022.png";

  document.querySelector("title").innerText = title
    ? `${title} - OSMX BLOG`
    : "OSMX Blog - Coding everywhere";
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", description);
  document
    .querySelector('meta[property="og:description"')
    .setAttribute("content", description);
  document
    .querySelector('meta[property="twitter:description"')
    .setAttribute("content", description);

  document
    .querySelector('meta[property="og:image"')
    .setAttribute("content", image);
  document
    .querySelector('meta[property="twitter:image"')
    .setAttribute("content", image);

  document
    .querySelector('meta[property="og:url"')
    .setAttribute("content", window.location.href);
  document
    .querySelector('meta[property="twitter:url"')
    .setAttribute("content", window.location.href);
}
