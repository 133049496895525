const routes = [
  {
    name: "Company",
    routes: [
      {
        name: "About",
        path: "https://osmx.me",
        tag: "a",
      },
      {
        name: "Terms of use",
        path: "/terms",
      },
      {
        name: "Privacy Policy",
        path: "/privacy",
      },
      {
        name: "Contact",
        path: "/contact",
      },
    ],
  },
];

export default routes;
