import styled from "styled-components";
import { queries } from "../../config";

export const Container = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  margin: 3rem 0;
`
export const Wrapper = styled.div`
  width: 85%;
  position: relative;
`
export const Header = styled.div`
  width: 100%;
  text-align: center;
  h2 {
    text-transform: capitalize;
    span {
      color: var(--primary);
      text-decoration: underline;
    }
  }
`
export const PostsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 3rem;
  @media ${queries.mobile} {
    grid-template-columns: 1fr;
  }
`
export const NoPosts = styled.div``