import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled(motion.div)`
  position: relative;
  width: 100%;
  padding: 2rem;
  box-shadow: 10px 15px 15px rgba(0, 0, 0, 0.07);
`
export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  gap: .5rem;
  flex-direction: column;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  img { 
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  span {
    font-weight: bold;
  }
`
export const Title = styled.h2`
  font-weight: 700;
  transition: 300ms;
  text-align: attr(data-dir);
  :hover {
    color: var(--primary);
  }
`
export const Excerpt = styled.div`
  text-align: attr(data-dir);
`

export const Image = styled.div`
  overflow: hidden;
  border-radius: .5rem;
  width: 100%;
  height: 300px;
  transition: 200ms;

  :hover {
    img {
      transform: scale(1.5) rotateZ(10deg);
    }
  }
  img {
    transition: 200ms;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`
export const Timestamp = styled.div``
