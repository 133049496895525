import {
  Email,
  Facebook,
  LinkedIn,
  Pinterest,
  Reddit,
  Telegram,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";

const SHARE_LINKS = {
  facebook: "https://www.facebook.com/sharer/sharer.php?u=custom_link",
  twitter: "https://twitter.com/intent/tweet?url=custom_link&text=custom_title",
  pinterest:
    "http://pinterest.com/pin/create/button/?url=custom_link&media=custom_image&description=custom_title",
  linkedin:
    "http://www.linkedin.com/shareArticle?mini=true&url=custom_link&title=custom_title",
  tumblr: "http://www.tumblr.com/share?v=3&u=custom_link&t=custom_title",
  whatsapp: "whatsapp://send?text=custom_link",
  email:
    "mailto:example@example.com?subject=custom_title&body=custom_link%20custom_text",
  reddit: "https://reddit.com/submit?url=custom_link&title=custom_title",
  yahoo: "https://compose.mail.yahoo.com/?body=custom_link",
  telegram: "https://telegram.me/share/url?url=custom_link&text=custom_title",
};

export default function ShareButton() {
  const onShare = (provider) => {
    const name = document.querySelector("title");
    const image = document.querySelector('meta[property="og:image"');
    const text = document.querySelector('meta[property="og:description"');

    const uri = SHARE_LINKS[provider]
      .replace("custom_link", window.location.href)
      .replace("custom_title", name.innerText)
      .replace("custom_image", image.getAttribute("content"))
      .replace("custom_text", text.getAttribute("content"));
    window.open(uri, "_blank", "width=500,height=600");
  };

  return (
    <div
      style={{
        display: "flex",
        gap: ".5rem",
        flexWrap: "wrap",
      }}
    >
      <IconButton
        onClick={() => onShare("facebook")}
        color="inherit"
        style={{ color: "#3b5998" }}
      >
        <Facebook />
      </IconButton>
      <IconButton
        onClick={() => onShare("linkedin")}
        color="inherit"
        style={{ color: "#007fb1" }}
      >
        <LinkedIn />
      </IconButton>
      <IconButton
        onClick={() => onShare("twitter")}
        color="inherit"
        style={{ color: "#00aced" }}
      >
        <Twitter />
      </IconButton>
      <IconButton
        onClick={() => onShare("pinterest")}
        color="inherit"
        style={{ color: "#cb2128" }}
      >
        <Pinterest />
      </IconButton>
      <IconButton
        onClick={() => onShare("whatsapp")}
        color="inherit"
        style={{ color: "#25D366" }}
      >
        <WhatsApp />
      </IconButton>
      <IconButton
        onClick={() => onShare("email")}
        color="inherit"
        style={{ color: "#7f7f7f" }}
      >
        <Email />
      </IconButton>
      <IconButton
        onClick={() => onShare("reddit")}
        color="inherit"
        style={{ color: "#ff4500" }}
      >
        <Reddit />
      </IconButton>
      <IconButton
        onClick={() => onShare("telegram")}
        color="inherit"
        style={{ color: "#37aee2" }}
      >
        <Telegram />
      </IconButton>
    </div>
  );
}
