import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Loader, PostWidget, RouteContainer } from "../../components";
import { getCategoryPosts } from "../../services";
import NotFound from "../notFound";
import { Container, Header, NoPosts, PostsWrapper, Wrapper } from "./styled";
// getCategoryPosts
export default function Category() {
  const Location = useLocation();
  const slug = Location.pathname.split("/").pop();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getCategoryPosts(slug);
      setPosts(response);
      setLoading(false);
    })();
  }, [slug]);

  if (loading) return <Loader fullScreen />;
  if (!posts.length) return <NotFound />;
  return (
    <RouteContainer>
      <Container>
        <Wrapper>
          <Header>
            <h2>
              All posts related to <span>{slug}</span>
            </h2>
          </Header>
          <PostsWrapper>
            {posts.map((prop, index) => (
              <PostWidget post={prop} key={index} />
            ))}
          </PostsWrapper>
        </Wrapper>
      </Container>
    </RouteContainer>
  );
}
