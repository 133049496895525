import React, { useEffect } from "react";
import { Container, Wrapper, Author, MainSide, Logo, Box, RoutesWrapper, Header, Navigators } from "./styled";
// import Switch from "@mui/material/Switch";
import routes from "./routes";
import { Link } from "react-router-dom";
import { getCategories } from "../../services";

export default function Footer() {
  const [routesList, setRoutesList] = React.useState([]);

  useEffect(() => {
    (async () => {
      const response = await getCategories();
      setRoutesList([
        {
          name: "Categories",
          routes: response.map((prop) => ({
            name: prop.name,
            path: `/category/${prop.slug}`,
          })),
        },
        ...routes,
      ]);
    })();
  }, []);

  return (
    <Container>
      <Wrapper>
        <MainSide>
          <Logo>
            <h2>OSMX BLOG</h2>
            <p>We provide best tutorials</p>
          </Logo>
          <Author>
            © 2022 <a href="https://osmx.me/">OSMX</a> All Rights Reserved.
          </Author>
        </MainSide>
        <RoutesWrapper>
          {routesList.map((root, index) => (
            <Box key={index}>
              <Header>{root.name}</Header>
              <Navigators>
                {root.routes.map((prop, key) =>
                  prop?.tag ? (
                    <a key={key} href={prop.path}>
                      {prop.name}
                    </a>
                  ) : (
                    <Link key={key} to={prop.path}>
                      {prop.name}
                    </Link>
                  )
                )}
              </Navigators>
            </Box>
          ))}
        </RoutesWrapper>
      </Wrapper>
    </Container>
  );
}
